import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const TNBC = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="KEYTRUDA was studied with 3 different chemotherapy regimens (paclitaxel, nab-paclitaxel, or gemcitabine and carboplatin) in a multicenter, randomized, double-blind, placebo-controlled trial in 847 patients with advanced TNBC." data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>Included patients with locally recurrent unresectable or metastatic TNBC, regardless of tumor PD&#8288;-&#8288;L1 expression, who had not been previously treated with chemotherapy in the metastatic setting.</p>
                        <p>Excluded patients with active autoimmune disease that required systemic therapy within 2 years of treatment and patients with a medical condition that required immunosuppression.</p> 
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized (2:1) (N=847)<sup>e,f</sup>',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray'
                },
                {
                    type: 'arrow-gray'
                }
            ],
        ]} />
    
        <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA",
                data: [
                    [
                        {
                            title: `
                                <div class="h7">Treatment</div>
                                <div>200&nbsp;mg on day 1 Q3W</div>
                            `,
                            description: [
                                {
                                    text: `paclitaxel (90&nbsp;mg/m<sup>2</sup> on days 1, 8, and 15 every 28 days)`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                                {
                                    text: `paclitaxel protein&#8288;-&#8288;bound (nab-paclitaxel) (100&nbsp;mg/m<sup>2</sup> on days 1, 8, and 15 every 28 days)`,
                                    conditional: 'or',
                                    textCenter: true
                                },
                                {
                                    text: `gemcitabine plus carboplatin (gem carbo) (1,000&nbsp;mg/m<sup>2</sup> and AUC 2&nbsp;mg/mL/min, respectively, on days 1 and 8 every 21 days)`,
                                    conditional: 'or',
                                    textCenter: true
                                }
                            ],
                            type: 'box-multi-color-green'
                        }
                    ]
                ]
            },
            {
                title: "Placebo",
                data: [
                    [
                        {
                            title: `
                                <div class="h7">Treatment</div>
                                <div>On day 1 Q3W</div>
                            `,
                            description: [
                                {
                                    text: `paclitaxel (90&nbsp;mg/m<sup>2</sup> on days 1, 8, and 15 every 28 days)`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                                {
                                    text: `paclitaxel protein&#8288;-&#8288;bound (nab-paclitaxel) (100&nbsp;mg/m<sup>2</sup> on days 1, 8, and 15 every 28 days)`,
                                    conditional: 'or',
                                    textCenter: true
                                },
                                {
                                    text: `gemcitabine plus carboplatin (gem carbo) (1,000&nbsp;mg/m<sup>2</sup> and AUC 2&nbsp;mg/mL/min, respectively, on days 1 and 8 every 21 days)`,
                                    conditional: 'or',
                                    textCenter: true
                                }
                            ],
                            type: 'box-multi-color-gray'
                        }
                    ]
                ]
            }
        ]} />

        <StudyCopyBlock 
            title="End Points"
            description={`
                <p><b>Main Efficacy Outcome Measures:</b></p>
                <ul>
                    <li>Overall survival (OS)</li>
                    <li>Progression-free survival (PFS)<sup>g</sup></li>
                </ul>
                <p><b>Additional Efficacy Outcome Measures:<sup>g</sup></b></p>
                <ul>
                    <li>Objective response rate (ORR)</li>
                    <li>Duration of response (DOR)</li>
                </ul>

                <p>Assessment of tumor status was performed at weeks 8, 16, and 24, then every 9 weeks for the first year, and every 12 weeks thereafter.</p>
            `} 
            footnotes={[
                {
                    label:'e.',
                    text: `
                        Randomization (2:1) stratified by chemotherapy treatment (paclitaxel or paclitaxel protein&#8288;-&#8288;bound vs gemcitabine and carboplatin), tumor PD&#8288;-&#8288;L1 expression (CPS ≥1 vs CPS <1) according to the PD&#8288;-&#8288;L1 IHC 22C3 pharmDx kit, and prior treatment with the same class of chemotherapy in the neoadjuvant setting (yes vs no).
                    `
                },
                {
                    label:'f.',
                    text: `
                        All study medications were administered via intravenous infusion.
                    `
                },
                {
                    label:'g.',
                    text: `
                        Assessed by blinded independent central review (BICR). PFS assessed by BICR according to Response Evaluation Criteria In Solid Tumors v1.1 (RECIST v1.1), modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ tested in the subgroup of patients with CPS ≥10.
                    `
                }
            ]}
            definitions={`
                AUC = area under the curve; IHC = immunohistochemistry.
            `}
        />
 
    </ComponentWrapper>
);

export default TNBC;
